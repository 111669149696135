<template>
    <BT-Blade-Items
        addBladeName="client-billable-item"
        bladeName="client-billable-items"
        :bladesData="bladesData"
        hideActions
        navigation="client-billable-items"
        :useServerPagination="true"
        :headers="[
            { text: 'Client', value: 'buyer.companyName', title: 1 },
            { text: 'Type', value: 'billableType' },
            { text: 'Date', value: 'dateOfItem', textFilter: 'toLongDateAndTime', subtitle: 1 },
            { text: 'Distance (Km)', value: 'distance', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Km: ' },
            { text: 'Zone', value: 'zone', subtitle: 3, prefix: 'Zone: ' }]"
        title="Clients' Billable Items">
        <template slot="zone" slot-scope="{ item }">
            <BT-Entity 
                v-if="item.zoneID != null" 
                navigation="zones"
                :itemValue="item.zoneID"
                itemText="zoneName" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Client-Billable-Items',
    props: {
        bladesData: null
    }
}
</script>