var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"client-billable-item","bladeName":"client-billable-items","bladesData":_vm.bladesData,"hideActions":"","navigation":"client-billable-items","useServerPagination":true,"headers":[
        { text: 'Client', value: 'buyer.companyName', title: 1 },
        { text: 'Type', value: 'billableType' },
        { text: 'Date', value: 'dateOfItem', textFilter: 'toLongDateAndTime', subtitle: 1 },
        { text: 'Distance (Km)', value: 'distance', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Km: ' },
        { text: 'Zone', value: 'zone', subtitle: 3, prefix: 'Zone: ' }],"title":"Clients' Billable Items"},scopedSlots:_vm._u([{key:"zone",fn:function(ref){
        var item = ref.item;
return [(item.zoneID != null)?_c('BT-Entity',{attrs:{"navigation":"zones","itemValue":item.zoneID,"itemText":"zoneName"}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }